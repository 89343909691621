import React, {useState, useEffect} from "react";
import ReactDOM from "react-dom";
import { useMoralis, useMoralisWeb3Api, useMoralisWeb3ApiCall, useWeb3ExecuteFunction } from "react-moralis";
import { BrowserRouter, Routes, Route } from "react-router-dom";


const tokenAddress = "0xEFCEF3cFA190eDb7b0f8D1D60118FDfe631b6dC2";


const App = () => {
  const { authenticate, enableWeb3, Moralis, isAuthenticated, user, logout } = useMoralis();
  const contractProcessor = useWeb3ExecuteFunction();
  const [checkRaceID, setCheckRaceID] = useState('');
  const [joinRaceID, setJoinRaceID] = useState('');
  const [createEntryFee, setEntryFee] = useState('');
  const [createRaceDuration, setRaceDuration] = useState('');

  const ERC20_ABI = [
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_raceType",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_entryFee",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_decimals",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_sponsoredReward",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_raceDuration",
          "type": "uint256"
        }
      ],
      "name": "createRace",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_raceID",
          "type": "uint256"
        }
      ],
      "name": "joinRace",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_raceID",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_position",
          "type": "uint256"
        }
      ],
      "name": "participantsPosition",
      "outputs": [
        {
          "internalType": "address",
          "name": "address",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
  ];


  const participantsPosition = async (raceID, position) => {
    
    let options = {
      chain: "avalanche testnet",
      contractAddress: tokenAddress,
      functionName: "participantsPosition",
      abi: ERC20_ABI,
      params: {_raceID: raceID, _position: position},
    }

    await contractProcessor.fetch({
      params: options
    })

  }

  const joinRace = async (raceID) => {
    
    let options = {
      chain: "avalanche testnet",
      contractAddress: tokenAddress,
      functionName: "joinRace",
      abi: ERC20_ABI,
      params: {_raceID: raceID},
    }

    await contractProcessor.fetch({
      params: options
    })

  }

  const createRace = async (entryFee, raceDuration) => {
    let options = {
      chain: "avalanche testnet",
      contractAddress: tokenAddress,
      functionName: "joinRace",
      abi: ERC20_ABI,
      params: {
        _raceType: 1,
        _entryFee: entryFee,
        _decimals: 18,
        _sponsoredReward: 1,
        _raceDuration: raceDuration
      },
    }

    await contractProcessor.fetch({
      params: options
    })

  }


  if (!isAuthenticated) {
    return (
      <div className="front">
        Connect your wallet and track your wellness!
        <br />
        <button onClick={() => authenticate({ signingMessage: "You are signing to connect to CoralApp!!" })}>Connect Your Wallet</button>
      </div>
    );
  }

  return (
    <div className="wrapper">
      
          <header>
            <h1>Welcome {user.get("ethAddress")}</h1>
            <button onClick={() => logout()}>Logout</button>
          </header>
            
        <section className="columns">
          
          <div className="column">
            <h2>Create New Race</h2>
            <input placeholder="Set Entry Fee" onChange={event => setEntryFee(event.target.value)} />
            <br/>
            <input placeholder="Set Race Duration (in hours)" onChange={event => setRaceDuration(event.target.value)} />
            <br/>
            <button onClick={() => createRace(createEntryFee, createRaceDuration)}>Create Race</button>
          </div>
          
          <div className="column">
            <h2>Join Race</h2>
            <input placeholder="Race ID" onChange={event => setJoinRaceID(event.target.value)} />
            <br/>
            <button onClick={() => joinRace(joinRaceID)}>Join Race</button>
          </div>
          
          <div className="column">
            <h2>Leaderboard</h2>
            <input placeholder="Race ID" onChange={event => setCheckRaceID(event.target.value)} />
            <br/>
            <button onClick={() => participantsPosition(checkRaceID,0)}>Check Race</button>
          </div>
          
        </section>


    </div>
  );
};


/**const DashboardRoute = () => {
  return (
    
    <></>

  );
};*/


export default App;
